:root{
  --r-color: #ce2c2c;
  --b-color: #000;
  
}

.gray {
  width: 50px;
  background-color: antiquewhite;
}

.gray .send {
  justify-content: center;
}

.send {
  height: 50px;
  width: 100px;
  background-color: #ce2c2c;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 15px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #000 !important;
  background-color: #fff !important;
}





.section-5 {
  margin-top: 100px;
}

@media (max-width: 740px) {
  .section-5 {
    margin-top: 50px;
  }

  .list1 {
    padding: 42px 133px;
  }

  .list-1 {
    padding: 42px 133px;
    margin-top: 0px;
  }

  .list2 {
    padding: 28px 134px;
  }

  .list3 {
    padding: 28px 134px;
  }

  .list4 {
    padding: 28px 134px;
  }

  .list5 {
    padding: 28px 134px;
  }

  .list-6 {
    height: 80px;
    padding: 15px 0px 0px 116px;
    margin: 24px 0px 36px 0px;
  }

  .list6 {
    height: 80px;
    padding: 15px 0px 0px 116px;

  }
}


.pricing-card{
  list-style-type: none;
  padding-left: 0%;
}
.card-header{
  padding: 32px 16px;
  font-size: 24px;
  color:white;
  background-color: var(--r-color);
  text-align: center;
}
.price-card-body{
  padding: 15px;
  font-size: 16px;
  text-align: center;
  background-color: #f1f1f1;
  color: var(--b-color);
  border-bottom: 0.5px solid rgb(182, 182, 182);
}
.card-price{
  text-align: center;
  background-color: #f1f1f1;
  padding: 16px;
  color:var(--b-color);
  font-size: 16px;
}
.money{
  font-size: 30px;
  margin-bottom: 10px;
}
.duration{
  color: #616161;
}
.card-footer{
  padding: 24px 16px;
  background-color: #F1F1F1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-btn{
  background-color: var(--r-color);
  padding: 12px 24px;
  color: #F1F1F1;
  font-size: 15px;
  border: none;
}
.bottom-btn:hover{
  background-color: gray
}