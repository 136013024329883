.bio {
  border-left: 2px solid #c31723;
  position: relative;
  left: -18px;
}

.my {
  font-size: 20px;
  color: #c31723;
  font-weight: bold;
}


.poo {
  color: #c31723;
  font-weight: bold;
  display: flex;
  font-size: 13px;
  justify-content: center;
}


.xxx {
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 13px;
}

.form-group label {
  color: #c31723;
  font-size: 12px;
}

.bio3 {
  border-left: 2px solid #c31723;
  position: relative;
  left: 19px;
}

.save {
  text-align: center;
}

.info {
  margin-left: 38px;
}

.personal {
  color: #c31723;
  font-size: 20px;
  font-weight: bold;
}

.profile {
  display: flex;
  justify-content: center;
}

/* input {border:0;outline:0;} */
input:focus {
  outline: none;
  background-color: transparent;
}

@media (max-width: 844px) {
  .rounded-circle {
    text-align: center;
  }

  .my {
    margin-top: 100px;
  }

  .info {
    margin-left: 0px;
  }

  .save {
    position: relative;
    bottom: -734px;
    margin-bottom: 20px;
  }

  .info {
    position: relative;
    bottom: 76px;
  }

  .xxx {
    margin-left: -22px;
  }

  .poo {
    margin-left: -22px;
  }
}

.form-group .form-control {
  background: #f4f9fc;
  height: 45px;
  border-color: rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {
  .info {
    margin-left: 0px;
  }

  .my {
    margin-top: 100px;
  }

  .save {
    position: relative;
    bottom: -734px;
  }

  .info {
    position: relative;
    bottom: 76px;
  }

}

.error-message {
  color: red !important;
  font-size: 10px;
  font-weight: 600;
}