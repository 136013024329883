 /* .forgot-btn{
    padding: 0;
    border: none;
    background: none;
    font-size: 12px;
    position: relative;
    bottom: -45px;
    left: -174px;
    color: #6c7fa6;
   
   
} */

.forgot-btn:focus { outline: none; } 

.forgot-btn{
    padding: 0;
    border: none;
    background: none;
    font-size: 12px;
    position: relative;
    bottom: -45px;
    left: -56px;
    color: #b41c1c;
   
}
.forgot{
    margin-left: -100px;
}
@media (max-width: 844px) {
   .forgot-btn{
       left: -59px;
   }
  }
  @media (max-width: 844px) {
    .header-login-form .form-control{
        margin: 5px;
    }
      
   }
  
.logout-btn{
    font-size: 15px;
}
.fa-arrow-right-from-bracket{
    padding-left: 3px;
}
.profile-link{
    border: 2px solid #b41c1c;
    padding: 0.4rem 1rem !important;  
    border-radius: 4px;
}