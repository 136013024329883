.section-1{
    margin-top: 80px;
}
.awards{
    margin-top: 54px;
}
@media (max-width: 844px) {
   .section-1{
       margin-top: 40px;
   }
   .awards{
    margin-top: -20px;
}
    }
.text{
    text-align: justify;
}