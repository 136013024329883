:root {
    --t-color: #f6f7f8;
    --r-color: #ce2c2c;
}

.section-4 {
    margin-top: 50px;
}

/* .filter-header {
    text-align: center;
} */


.filter-panel {
    margin-left: 20px;
    padding-top: 16px;
}

.filter-panel-body {
    margin-top: 10px;
    padding-bottom: 20px;
}

.form-check{
    margin: 5px;
}
.show-more{
    text-decoration: underline !important;
}
.show-more:hover{
    text-decoration: underline;
}



/* .form-check-label{
    color: #757575;
    font-size: 15px;
} */


/* .form-check-input {
    color: #757575;
    display: block;
    font-size: 13px;
    line-height: 18px;
    overflow: hidden;
    border-radius: 8px !important;
    text-overflow: ellipsis;
    user-select: none;
} */

/* .form-check-input:hover{
    color: #ce2c2c !important;
} */



 








/* -- Side Bar -- */


.select {
    margin-top: 8px;
    padding: 4px 12px;
    border: none;
    color: #757575;
    font-size: 17px;
}