

.form-control:focus{
    outline: none;
}
.btn-main5{
    border: none;
    background-color: #d02c2c;
    color: #fff;
    border-radius: 8px;
    width: 70px;
    height: 30px;
    font-size: 12px;
}
.password{
    margin-top: 70px;
}