.btn-main3{
    border: none;
    background-color: #d02c2c;
    color: #fff;
    border-radius: 8px;
    width: 70px;
    height: 30px;
    font-size: 12px;
}
.change{
    margin-top: 65px;
}
