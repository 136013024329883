@import url("https://fonts.googleapis.com/css?family=Exo:500,600,700|Roboto&display=swap");

:root{
  --primary-color: #ce2c2c;
  --secondary-color: #b41c1c;
}
html {
  overflow-x: hidden;
}

body {
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #6F8BA4;
  font-weight: 400;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Exo", sans-serif;
  font-weight: 700;
  color: #222;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 44px;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  line-height: 30px;
}

.navbar-toggle .icon-bar {
  background: var(--primary-color);
}

input[type="email"], input[type="password"], input[type="text"], input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
}

input[type="email"]:focus, input[type="password"]:focus, input[type="text"]:focus, input[type="tel"]:focus {
  box-shadow: none;
  border: 1px solid var(--primary-color);
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid var(--primary-color);
}

.py-7 {
  padding: 7rem 0px;
}

.error-message {
  color: red !important;
  font-size: 10px;
  font-weight: 600;
}

.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: .5px;
  padding: .75rem 2rem;
  font-family: "Exo", sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: all .35s ease;
}

.btn.btn-icon i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
  padding-left: 15px;
}

.btn:focus {
  outline: 0px;
  box-shadow: none;
}

.btn-main {
  background: var(--primary-color) !important;
  color: #fff !important;
  border-color: var(--primary-color);
}

.btn-main:hover {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}

.btn-main-2 {
  background: var(--secondary-color);
  color: #fff;
  border-color: var(--secondary-color);
}


.btn-main-2:hover {
  background: var(--secondary-color);
  color: #fff;
  border-color: var(--primary-color);
}


.btn-solid-border {
  border: 2px solid var(--primary-color);
  background: transparent;
  color: var(--primary-color);
}

.btn-solid-border:hover {
  border: 2px solid var(--primary-color);
  color: #fff;
  background: var(--primary-color);
}

.btn-solid-border:hover.btn-icon i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.btn-solid-border.btn-icon i {
  border-left: 1px solid rgba(0, 0, 0, 0.09);
}

.btn-transparent {
  background: transparent;
  color: #222;
  border-color: #6F8BA4;
}

.btn-transparent:hover {
  background: #6F8BA4;
  color: #fff;
}

.btn-white {
  background: #fff;
  border-color: #fff;
  color: #222;
}

.btn-white:hover {
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.btn-solid-white {
  border-color: #fff;
  color: #fff;
}

.btn-solid-white:hover {
  background: #fff;
  color: #222;
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 50px !important;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.bg-gray {
  background: #eff0f3;
}

.bg-primary {
  background: var(--primary-color);
}

.bg-primary-dark {
  background: #152440;
}

.bg-primary-darker {
  background: #090f1a;
}

.bg-dark {
  background: #222;
}

.bg-gradient {
  background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}

.section {
  padding: 40px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-bottom {
  padding-bottom: 100px;
}
@media (max-width: 844px) {
  .section{
    padding: 0px;
  }
 
}

.subtitle {
  color: var(--primary-color);
  font-size: 14px;
  letter-spacing: 1px;
}

.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: var(--primary-color);
}

.overly-2 {
  position: relative;
}

.overly-2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 2.25rem;
}

.text-lg {
  font-size: 3.75rem;
}

.no-spacing {
  letter-spacing: 0px;
}

/* Links */
a {
  color: #222;
  text-decoration: none;
  transition: all .35s ease;
}

a:focus, a:hover {
   color: var(--secondary-color);
   text-decoration: none; 
}

a:focus {
  outline: none;
}

.content-title {
  font-size: 40px;
  line-height: 50px;
}

.page-title {
  padding: 120px 0px 70px 0px;
  position: relative;
}

.page-title .block h1 {
  color: #fff;
}

.page-title .block p {
  color: #fff;
}

.page-title .breadcumb-nav {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
}

.slick-slide:focus, .slick-slide a {
  outline: none;
}

@media (max-width: 480px) {
  h2, .h2 {
    font-size: 1.3rem;
    line-height: 36px;
  }
}

.title-color {
  color: var(--primary-color);
}

.secondary-bg {
  background: var(--primary-color);
}

.section-title {
  margin-bottom: 54px;
}


.section-title h2 {
  color: var(--primary-color);
 margin-right: 50px;

}
.doctor-skills{
  margin-top: 60px;
}
/* .doctor-qualification{
  margin-top: 60px;
} */
.text-lg {
  font-size: 50px;
}

.gray-bg {
  background: #f4f9fc;
}

@media (max-width: 480px) {
  .text-lg {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .text-lg {
    font-size: 28px;
  }
}

#navbarmain {
  padding: 20px 0px;
}

#navbarmain .nav-link {
  font-weight: 600;
  padding: 10px 15px;
  color: #222;
  font-family: "Exo", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  transition: all .25s ease;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-brand {
  margin-top: 10px;
}

.dropdown .dropdown-menu {
  position: absolute;
  display: block;
  background: #fff;
  min-width: 240px;
  top: 130%;
  left: 0;
  right: 0px;
  opacity: 0;
  padding: 0px;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
  border: 0px;
  border-top: 5px solid var(--secondary-color);
  border-radius: 0px;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 115%;
}

.dropdown .dropdown-item {
  padding: 13px 20px;
  border-bottom: 1px solid #eee;
  background: transparent;
  font-weight: 400;
  color: #555;
}

.dropdown .dropdown-item:hover {
  color: var(--secondary-color);
}

.header-top-bar {
  background: var(--primary-color);
  font-size: 14px;
  padding: 10px 0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #fff;
}

.top-bar-info li a {
  color: #fff;
  margin-right: 20px;
}

.top-right-bar a span {
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.top-right-bar a i {
  color: #fff;
  margin-right: 10px;
}

.bg-1 {
  background: url("./assets/images/22.jpg") no-repeat 50% 50%;
  background-size: cover;
  position: relative;
}

.banner {
  position: relative;
  overflow: hidden;
  background: #fff;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./assets/images/slider-bg-1.jpg") no-repeat;
  background-size: cover;
  background-position: center bottom;
  min-height: 550px;
}

.banner .block {
  padding-top: 150px;
}

.banner .block h1 {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  color: #fff;
}

.letter-spacing {
  letter-spacing: 2px;
}

.text-color {
  color: var(--primary-color);
}

.text-color-2 {
  color: var(--secondary-color);
}

.divider {
  width: 40px;
  height: 5px;
  background: var(--secondary-color);
 
}
.divider1 {
  width: 40px;
  height: 5px;
  background: var(--secondary-color);
  position: relative;
  left: 220px;
}
.divider2 {
  width: 40px;
  height: 5px;
  background: var(--secondary-color);
  position: relative;
  left: -27px;
}
@media (max-width: 844px) {
  .divider1 {
    position: relative;
    left: 162px;
    width: 30px;
  }
  .divider2 {
    position: relative;
    left: 0px;
    width: 30px;
  }

}
@media (max-width: 844px) {
.section-title h2{
  margin-right: 0px;
}

}
@media (max-width: 844px) {
  .section-title {
    margin-top: 30px;
  }
  
  }
  
  

@media (max-width: 480px) {
  .banner .block h1 {
    font-size: 38px;
    line-height: 20px;
    font-size: 30px;
  }
  .banner {
    min-height: 450px;
    /* background: #fff !important; */
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./assets/images/slider-bg-1.jpg") no-repeat;
    background-size: cover;
  background-position: center bottom;

  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 28px;
    line-height: 10px;
    font-size: 30px;
  }
  .banner {
    min-height: 450px;
    /* background: #fff !important; */
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./assets/images/slider-bg-1.jpg") no-repeat;
    background-size: cover;
  background-position: center bottom;

  }
}

@media (max-width: 768px) {
  .banner .block h1 {
    font-size: 56px;
    line-height: 40px;
    font-size: 30px;
  }
  .banner {
    /* background: #fff !important; */
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./assets/images/slider-bg-1.jpg") no-repeat;
    background-size: cover;
  background-position: center bottom;

  }
}

@media (max-width: 992px) {
  .banner {
    /* background: #fff !important; */
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./assets/images/slider-bg-1.jpg") no-repeat;
    background-size: cover;
  background-position: center bottom;

  }
}

.about-img img {
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
}

.award-img {
  height: 120px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #eff0f3;
  /* filter: blur(10px); */
}

.appoinment-content {
  position: relative;
}

.appoinment-content img {
  width: 85%;
}

.appoinment-content .emergency {
  position: absolute;
  content: "";
  right: 10px;
  bottom: 20px;
  background: var(--primary-color);
  padding: 48px;
}

.appoinment-content .emergency h2 {
  color: #fff;
}

.appoinment-content .emergency i {
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.appoinment-form {
  margin-top: 40px;
}

.appoinment-form .form-control {
  background: #f4f9fc;
  height: 55px;
  border-color: rgba(0, 0, 0, 0.05);
}

.appoinment-form textarea.form-control {
  height: auto;
}

.header-login-card{
  /* display: flex!important; */
  justify-content: right;
}
@media (max-width: 667px) {
 .header-login-card{
  line-height: 5;
 }
 .header-login-form{
   line-height: 5;
 }
}

.header-login-form .form-control {
  background: #f4f9fc;
  height: 45px;
  border-color: rgba(0, 0, 0, 0.05);
}

.header-login-form textarea.form-control {
  height: auto;
}

.header-login-form .btn-main{
  padding: 0.6rem 2rem!important;
}

.client-thumb {
  text-align: center;
}

.features {
  margin-top: -70px;
}

.feature-item {
  flex-basis: 33.33%;
  margin: 0px 10px;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
}

.feature-item .feature-icon i {
  font-size: 50px;
  color: var(--primary-color);
}

.feature-item h4 {
  color: var(--primary-color);
}

.feature-item p {
  font-size: 14px;
}

.feature-section.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.w-hours li {
  padding: 6px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.counter-stat {
  text-align: center;
  padding: 55px 0px 40px 0px;
  position: relative;
}

.counter-stat i {
  display: block;
  color: rgba(255, 255, 255, 0.06);
  font-size: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  -webkit-transform: translateY(25px);
  transform: translateY(25px);
}

.counter-stat span {
  font-size: 70px;
  color: #fff;
}

.counter-stat p {
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.7);
}

.mb--80 {
  margin-bottom: -80px;
}

.service {
  padding-top: 180px;
}

.service .service-item {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}

.service .icon {
  float: left;
  margin-bottom: 10px;
}

.service i {
  color: var(--secondary-color);
}

.service h4 {
  padding-left: 20px;
}

.service .content {
  clear: both;
}

.service-block {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 38px rgba(21, 40, 82, 0.07);
}

.service-block img {
  width: 100%;
  margin-top: -60px;
  border: 5px solid #fff;
}

.department-service {
  margin-bottom: 40px;
}

.department-service li {
  margin-bottom: 10px;
}

.department-service li i {
  color: var(--secondary-color);
}

.doctors .btn-group .btn {
  border-radius: 0px;
  margin: 0px 2px;
  text-transform: capitalize;
  font-size: 16px;
  padding: .6rem 1.5rem;
  cursor: pointer;
}

.doctors .btn-group .btn.active {
  box-shadow: none !important;
  border-color: transparent;
  background: var(--secondary-color);
  color: #fff;
}

.doctors .btn-group .btn.focus {
  box-shadow: none !important;
  border-color: transparent;
}

.doctors .btn-group .btn:focus {
  box-shadow: none !important;
  border-color: transparent;
  background: var(--secondary-color);
  color: #fff;
}

.doctors .btn-group .btn:hover {
  box-shadow: none !important;
  border-color: transparent;
  background: var(--secondary-color);
  color: #fff;
}

.doctors .btn-group > .btn-group:not(:last-child) > .btn, .doctors .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .doctors .btn-group > .btn:not(:first-child) {
  border-radius: 3px;
}

.doctor-inner-box {
  overflow: hidden;
}
/* .doctor-single{
  margin-top: 40px;
} */

.doctor-inner-box .doctor-profile {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
}
.doctor-profile .doctor-img{
  height: 300px;
  overflow: hidden;
}
.doctor-profile .doctor-img img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.doctor-inner-box .doctor-profile .doctor-img {
  transition: all .35s ease;
}

.doctor-inner-box .doctor-profile .doctor-img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.lh-35 {
  line-height: 35px;
}

.doctor-info li {
  margin-bottom: 10px;
  color: #222;
}

.doctor-info li i {
  margin-right: 20px;
  color: var(--secondary-color);
}

.read-more {
  color: var(--primary-color);
}

@media (max-width: 480px) {
  .doctors .btn-group {
    display: block;
  }
  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

@media (max-width: 400px) {
  .doctors .btn-group {
    display: block;
  }
  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

@media (max-width: 768px) {
  .doctors .btn-group {
    display: block;
  }
  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

.cta {
  /* background: url("./assets/images/bg-4.jpg") no-repeat 50% 50%; */
  background-size: cover;
  position: relative;
}

.cta:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(34, 58, 102, 0.95);
}

.mb-30 {
  margin-bottom: 30px;
}

.text-color-primary {
  color: var(--primary-color);
}

.cta-section {
  margin-bottom: -80px;
}

.cta-2 {
  /* background: url("./assets/images/cta-bg.png") no-repeat; */
  background-position: center center;
}

.cta-page {
  /* background: url("./assets/images/banner.jpg") no-repeat; */
  background-size: cover;
  position: relative;
}

.testimonial {
  position: relative;
}

.testimonial:before {
  width: 48%;
  height: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  content: "";
  background: url("./assets/images/bg-2.jpg") no-repeat 50% 50%;
}

.testimonial .slick-dots {
  text-align: left;
}

.testimonial-block {
  position: relative;
  margin-bottom: 20px;
}

.testimonial-block p {
  background: #fff;
  font-size: 18px;
}

.testimonial-block .client-info {
  margin-bottom: 20px;
}

.testimonial-block .client-info h4 {
  margin-bottom: 0px;
}

.testimonial-block i {
  font-size: 60px;
  position: absolute;
  right: 46px;
  bottom: 89px;
  opacity: .08;
}

.testimonial-block .slick-dots {
  text-align: left;
}

.testimonial-wrap-2 .slick-dots {
  margin-left: -10px;
}

.testimonial-block.style-2 {
  background: #fff;
  padding: 30px;
  margin: 0px 4px;
  margin-bottom: 30px;
}

.testimonial-block.style-2 .testimonial-thumb {
  float: left;
}

.testimonial-block.style-2 .testimonial-thumb img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  border: 5px solid #eff0f3;
  margin-top: -5px;
}

.testimonial-block.style-2 .client-info p {
  clear: both;
  background: transparent;
}

.testimonial-block.style-2 i {
  bottom: -20px;
  color: var(--secondary-color);
  opacity: .3;
}

@media (max-width: 480px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

@media (max-width: 400px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

@media (max-width: 992px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

.contact-form-wrap .form-group {
  margin-bottom: 20px;
}

.contact-form-wrap .form-group .form-control {
  height: 60px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  width: 100%;
  background: #f4f9fc;
}

.contact-form-wrap .form-group-2 {
  margin-bottom: 13px;
}

.contact-form-wrap .form-group-2 textarea {
  height: auto;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  background: #f4f9fc;
  width: 100%;
}

.social-icons li {
  margin: 0 6px;
}

.social-icons a {
  margin-right: 10px;
  font-size: 18px;
}

/* .google-map {
  position: relative;
} */



.google-map {
  width: 100% !important;
  height: 500px !important;
} 
.google-map iframe{
  height: 100%;
  width: 100%;
}


.mt-90 {
  margin-top: 90px;
}

.contact-block {
  text-align: center;
  border: 5px solid #EEF2F6;
  padding: 50px 25px;
}

.contact-block i {
  font-size: 50px;
  margin-bottom: 15px;
  display: inline-block;
  color: var(--secondary-color);
}

.blog-item-content h2 {
  font-weight: 600;
  font-size: 38px;
}

/*=================================================================
  Single Blog Page
==================================================================*/
.nav-links .page-numbers {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #eee;
  text-align: center;
  padding-top: 13px;
  font-weight: 600;
  margin-right: 10px;
}

.nav-links .page-numbers:hover {
  background: var(--primary-color);
  color: #fff;
}

.nav-links .page-numbers.current {
  background: var(--primary-color);
  color: #fff;
}

.comment-area .comment-thumb {
  margin-right: 20px;
  margin-bottom: 30px;
}

.comment-area h5 {
  font-size: 18px;
  font-weight: 500;
}

.comment-area span {
  font-size: 14px;
}

.posts-nav h6 {
  font-weight: 500;
}

.quote {
  font-size: 22px;
  color: var(--primary-color);
  padding: 40px;
  font-style: italic;
  border-left: 5px solid var(--secondary-color);
  margin: 25px 0px;
}

.tag-option a {
  border: 1px solid #eff0f3;
  padding: 6px 12px;
  color: #6F8BA4;
  font-size: 14px;
}

.comment-form .form-control {
  background: #f7f8fb;
  border-radius: 5px;
  border-color: #f7f8fb;
  height: 50px;
}

.comment-form textarea.form-control {
  height: auto;
}

.post.post-single {
  border: none;
}

.post.post-single .post-thumb {
  margin-top: 30px;
}

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 20px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
}

.post-comments .comment-author a {
  color: var(--primary-color);
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}

.post-comments .comment-button {
  color: var(--primary-color);
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: var(--primary-color);
}

.post-excerpt {
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt p {
  margin: 0 0 30px;
}

.post-excerpt blockquote.quote-post {
  margin: 20px 0;
}

.post-excerpt blockquote.quote-post p {
  line-height: 30px;
  font-size: 20px;
  color: var(--primary-color);
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: var(--primary-color);
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid var(--primary-color);
}

.comment-reply-form textarea, .comment-reply-form .btn-main {
  height: auto;
}

.sidebar-widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.sidebar-widget h5 {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.sidebar-widget h5:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 35px;
  height: 3px;
  background: var(--secondary-color);
}

.sidebar-widget.latest-post .media img {
  border-radius: 7px;
}

.sidebar-widget.latest-post .media h6 {
  font-weight: 500;
  line-height: 1.4;
}

.sidebar-widget.latest-post .media p {
  font-size: 12px;
}

.sidebar-widget.category ul li {
  margin-bottom: 10px;
}

.sidebar-widget.category ul li a {
  color: #222;
  transition: all 0.3s ease;
}

.sidebar-widget.category ul li a:hover {
  color: var(--primary-color);
  padding-left: 5px;
}

.sidebar-widget.category ul li span {
  margin-left: 10px;
}

.sidebar-widget.tags a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .075em;
  line-height: 41px;
  height: 41px;
  font-weight: 500;
  border-radius: 20px;
  color: #666;
  display: inline-block;
  background-color: #eff0f3;
  margin: 0 7px 10px 0;
  padding: 0 25px;
  transition: all .2s ease;
}

.sidebar-widget.tags a:hover {
  color: #fff;
  background: var(--primary-color);
}

.sidebar-widget.schedule-widget {
  background: #f4f9fc;
  padding: 25px;
}

.sidebar-widget.schedule-widget ul li {
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}

.search-form {
  position: relative;
}

.search-form i {
  position: absolute;
  right: 15px;
  top: 35%;
}

.footer {
  padding-bottom: 10px;
  margin-top: 50px;
}
.temp{
  margin-top: 50px;
}
.temp1{
  margin-top: 50px;
}
@media (max-width: 844px) {
 .temp1{
   margin-top: 40px;
 }
 .temp{
   margin-top: 0px;
 }
  }

.footer .copyright a {
  font-weight: 600;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  font-weight: 600;
  letter-spacing: 1px;
}

.logo h3 {
  color: var(--primary-color);
}

.logo span {
  color: var(--primary-color);
}

.widget .divider {
  height: 3px;
}

.widget h4 {
  color: var(--primary-color);
}

.widget .footer-menu a {
  color: #6F8BA4;
}

.widget .footer-menu a:hover {
  color: var(--secondary-color);
}

.footer-contact-block span {
  font-weight: 400;
  color: #6F8BA4;
}

.footer-contact-block i {
  font-size: 20px;
}

.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li .fb {
  width: 45px;
  height: 45px;
  background: #3E5B98;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 14px;
}
.footer-socials li .tw {
  width: 45px;
  height: 45px;
  background: #57bafc;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 14px;
}
.footer-socials li .link {
  width: 45px;
  height: 45px;
  background: #0077b5;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 14px;
}

.widget-contact h6 {
  font-weight: 500;
  margin-bottom: 18px;
}

.widget-contact h6 i {
  color: var(--secondary-color);
}

.subscribe {
  position: relative;
}

.subscribe .form-control {
  border-radius: 50px;
  height: 60px;
  padding-left: 25px;
  border-color: #eee;
}

.subscribe .btn {
  position: absolute;
  right: 6px;
  top: 6px;
}

.backtop {
  position: fixed;
  background: var(--secondary-color);
  z-index: 9999;
  display: inline-block;
  right: 55px;
  width: 60px;
  height: 60px;
  bottom: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 50px;
}

.backtop i {
  color: #fff;
  font-size: 12px;
}

.reveal {
  transition: all .6s;
  cursor: pointer;
  opacity: 1;
}

.blur-text{
  filter: blur(3px);
}

.doctor-details p{
  margin-bottom: 0;
}

.team-profile{
  height: 400px;
  overflow: hidden;
}

.team-profile img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ic-main{
  font-size: 13px;
  padding: 5px;
}
#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: red; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

#myBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}
.circle{
height: 60px;
width: 60px;
background-color: #b41c1c;
border-radius: 50%;
 padding: 19px 24px;
 cursor: pointer;
 color: #fff;
 position: fixed;
 left: 1100px;

 
}
.col-lg-1 a{
  color: #fff;
}
.owl-theme .owl-nav .owl-dots {
  margin-top: -40px! important;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #b41c1c! important;
}
.confirmation-content h2{
  color: #b41c1c;
}



/* Register Request */

.verify-btn{
  text-align: center;
}


/* -- Profile Detail -- */
.liked-button{
  background-color: #388e3c !important;
  border:none;
}




/*-- OTP --*/

.form-inline{
  justify-content:  center;
}
.btn-main4{
  border: none;
  background-color: var(--primary-color) !important;
  color: #fff !important;
  border-radius: 8px;
  width: 100px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
}

.btn-main4:hover{
background-color: var(--secondary-color) !important;
}

.abcd{
  position: relative ;
}